<AppSnackBar
  :message="showSuccessMsg.Message"
  :notificationType="showSuccessMsg.type"
  v-if="isShowSuccessMsg"
>
</AppSnackBar>
<AppSnackBar
  :message="showErrorMsg.Message"
  :notificationType="showErrorMsg.type"
  v-if="isShowErrorMsg"
>
</AppSnackBar>
<AppSnackBar
  :message="showImportMsg.Message"
  :notificationType="showImportMsg.type"
  v-if="isShowImportMsg"
>
</AppSnackBar>

<AppSnackBar
  :message="showMsg.Message"
  :notificationType="showMsg.type"
  v-if="isShowMsg"
></AppSnackBar>

<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>

<app-loader v-if="isLoading || loader || isColumnLoading"></app-loader>
<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />

  <div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div class="px-20 px-10-sm">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100-vsm pt-12 pb-16 w-100 border-bottom-normal-1 mb-16"
        >
          <h2 class="font-18 font-w-600 mb-2 mb-sm-0">All Audience</h2>
          <div
            class="w-100-vsm d-flex gap-3"
            v-if="(fetchController == 'available' || searchWord|| isSearching)"
          >
            <kbutton
              :disabled="!hasCreateAccess() && !hasEditAccess()"
              :fill-mode="'outline'"
              :class="'w-100-vsm h-38 border-normal-1 text-color bg-white hvr-bg-primary'"
              :theme-color="'primary'"
              @click="openConFigureFields"
              >Configure Fields
            </kbutton>

            <buttongroup :class="'w-100-vsm'" class="d-flex gap-0">
              <kbutton
                :disabled="!hasCreateAccess()"
                :theme-color="'primary'"
                :class="'btn-radius-8-0-0-8 w-100-vsm'"
                @click="btnAddAudience"
                >Add Audience
              </kbutton>
              <DropDownButton
                v-if="drodownImportExport?.length"
                :class="'btn-size-38'"
                :buttonClass="'btn-radius-0-8-8-0 btn-bg-dark-blue'"
                :theme-color="'primary'"
                :size="''"
                :popup-settings="popupSettings"
                :items="drodownImportExport"
                @itemclick="onChangeDrodownImportExport"
                :icon="'arrow-60-down'"
              />
            </buttongroup>
          </div>
        </div>
        <h1>{{part}}</h1>
      </div>
    </div>
    <div class="dashboard-content py-0 px-20 px-10-sm">
      <div
         v-if="allAudienceData?.items?.length == 0  && !isLoading && !searchWord && !isSearching && !isTagID"
      >
        <!--dashboard-content-->
        <div
          class="dashboard-content bg-white radius-5 min-height-calc-100-182 d-flex justify-content-center align-items-center"
        >
          <div class="width-747 text-center">
            <svg
              width="217"
              height="184"
              viewBox="0 0 217 184"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.05" cx="108" cy="92" r="92" fill="#253858" />
              <g filter="url(#filter0_bdd_666_102160)">
                <rect
                  x="31.7822"
                  y="53.0176"
                  width="154.563"
                  height="98.2819"
                  rx="1.93432"
                  fill="white"
                  fill-opacity="0.15"
                  shape-rendering="crispEdges"
                />
              </g>
              <g filter="url(#filter1_bd_666_102160)">
                <rect
                  x="28.0996"
                  y="43.9238"
                  width="161.928"
                  height="102.965"
                  rx="1.88497"
                  fill="white"
                  fill-opacity="0.15"
                  shape-rendering="crispEdges"
                />
              </g>
              <g filter="url(#filter2_dd_666_102160)">
                <rect
                  x="23"
                  y="33"
                  width="170.842"
                  height="108.97"
                  rx="3.69388"
                  fill="white"
                />
              </g>
              <line
                x1="36"
                y1="86.5383"
                x2="180"
                y2="86.5383"
                stroke="black"
                stroke-opacity="0.04"
                stroke-width="0.923471"
              />
              <rect
                opacity="0.2"
                x="38"
                y="47"
                width="27.7041"
                height="27.7041"
                rx="13.8521"
                fill="#14C57B"
              />
              <rect
                opacity="0.6"
                x="76"
                y="49"
                width="55"
                height="6"
                rx="3"
                fill="#C7CBDB"
              />
              <rect
                opacity="0.3"
                x="76"
                y="62"
                width="104"
                height="3.5"
                rx="1.75"
                fill="#C7CBDB"
              />
              <rect
                opacity="0.3"
                x="76"
                y="71"
                width="94"
                height="3.5"
                rx="1.75"
                fill="#C7CBDB"
              />
              <mask
                id="mask0_666_102160"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="38"
                y="45"
                width="28"
                height="28"
              >
                <rect
                  x="38"
                  y="45"
                  width="27.7041"
                  height="27.7041"
                  rx="13.8521"
                  fill="#A4C3F3"
                />
              </mask>
              <g mask="url(#mask0_666_102160)">
                <path
                  d="M48.6959 62.9591C49.846 62.4912 50.9474 62.2573 52 62.2573C53.0526 62.2573 54.1442 62.4912 55.2749 62.9591C56.425 63.4074 57 64.0019 57 64.7427C57 65.4371 56.4371 66 55.7427 66H48.2573C47.5629 66 47 65.4371 47 64.7427C47 64.0019 47.5653 63.4074 48.6959 62.9591ZM53.7544 60.269C53.2671 60.7563 52.6823 61 52 61C51.3177 61 50.7329 60.7563 50.2456 60.269C49.7583 59.7817 49.5146 59.1969 49.5146 58.5146C49.5146 57.8324 49.7583 57.2476 50.2456 56.7602C50.7329 56.2534 51.3177 56 52 56C52.6823 56 53.2671 56.2534 53.7544 56.7602C54.2417 57.2476 54.4854 57.8324 54.4854 58.5146C54.4854 59.1969 54.2417 59.7817 53.7544 60.269Z"
                  fill="#14C57B"
                />
              </g>
              <rect
                opacity="0.2"
                x="38"
                y="98"
                width="27.7041"
                height="27.7041"
                rx="13.8521"
                fill="#9B5FFC"
              />
              <rect
                opacity="0.6"
                x="76"
                y="100"
                width="55"
                height="6"
                rx="3"
                fill="#C7CBDB"
              />
              <rect
                opacity="0.3"
                x="76"
                y="113"
                width="104"
                height="3.5"
                rx="1.75"
                fill="#C7CBDB"
              />
              <rect
                opacity="0.3"
                x="76"
                y="122"
                width="94"
                height="3.5"
                rx="1.75"
                fill="#C7CBDB"
              />
              <mask
                id="mask1_666_102160"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="38"
                y="96"
                width="28"
                height="28"
              >
                <rect
                  x="38"
                  y="96"
                  width="27.7041"
                  height="27.7041"
                  rx="13.8521"
                  fill="#A4C3F3"
                />
              </mask>
              <g mask="url(#mask1_666_102160)">
                <path
                  d="M48.6959 112.959C49.846 112.491 50.9474 112.257 52 112.257C53.0526 112.257 54.1442 112.491 55.2749 112.959C56.425 113.407 57 114.002 57 114.743C57 115.437 56.4371 116 55.7427 116H48.2573C47.5629 116 47 115.437 47 114.743C47 114.002 47.5653 113.407 48.6959 112.959ZM53.7544 110.269C53.2671 110.756 52.6823 111 52 111C51.3177 111 50.7329 110.756 50.2456 110.269C49.7583 109.782 49.5146 109.197 49.5146 108.515C49.5146 107.832 49.7583 107.248 50.2456 106.76C50.7329 106.253 51.3177 106 52 106C52.6823 106 53.2671 106.253 53.7544 106.76C54.2417 107.248 54.4854 107.832 54.4854 108.515C54.4854 109.197 54.2417 109.782 53.7544 110.269Z"
                  fill="#9B5FFC"
                />
              </g>
              <defs>
                <filter
                  id="filter0_bdd_666_102160"
                  x="23.7431"
                  y="44.9784"
                  width="170.641"
                  height="114.36"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImage" stdDeviation="4.01957" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_666_102160"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1.81524" />
                  <feGaussianBlur stdDeviation="1.81524" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_backgroundBlur_666_102160"
                    result="effect2_dropShadow_666_102160"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4.01957" />
                  <feGaussianBlur stdDeviation="2.00978" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_666_102160"
                    result="effect3_dropShadow_666_102160"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect3_dropShadow_666_102160"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_bd_666_102160"
                  x="20.3047"
                  y="36.1289"
                  width="177.518"
                  height="118.555"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_666_102160"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1.76009" />
                  <feGaussianBlur stdDeviation="1.76009" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_backgroundBlur_666_102160"
                    result="effect2_dropShadow_666_102160"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_666_102160"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter2_dd_666_102160"
                  x="0.8367"
                  y="18.2245"
                  width="215.169"
                  height="153.296"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="7.38777" />
                  <feGaussianBlur stdDeviation="11.0816" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_666_102160"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1.84694" />
                  <feGaussianBlur stdDeviation="1.84694" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_666_102160"
                    result="effect2_dropShadow_666_102160"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_666_102160"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>

            <p class="color-light2 mt-3 mb-5">
              There are no members added as audience in your voucher yet. Click
              Add Audience to start adding you club members here.
            </p>

            <div class="width-482 mx-auto">
              <buttongroup>
                <kbutton
                  :disabled="!hasCreateAccess()"
                  :theme-color="'primary'"
                  :class="'btn-radius-4-0-0-4'"
                  @click="btnAddAudience"
                >
                  Add Audience</kbutton
                >
              </buttongroup>
            </div>
          </div>
        </div>
      </div>
      <template v-if="checkedColumns?.length">
        <Grid
          v-if="(allAudienceData?.items?.length>0 || searchWord?.length || isSearching || isTagID )"
          ref="grid"
          :class="'first-col-checkbox-center all-audience-table table-design-2 th-td-checkbox-style-2 mb-60'"
          :data-items="allAudienceData?.items"
          :take="take"
          :skip="skip"
          :total="total"
          :pageable="gridPageable"
          :selectable="true"
          :selected-field="selectedField"
          :columns="columns"
          @selectionchange="onSelectionChange"
          @datastatechange="dataStateChange"
          @headerselectionchange="onHeaderSelectionChange"
          :sortable="true"
          :sort="sort"
          @sortchange="sortChangeHandler"
        >
          <toolbar>
            <div class="d-flex flex-wrap justify-content-between w-100">
              <div
                class="d-flex flex-wrap align-items-start justify-content-between w-100"
              >
                <div
                  class="search-box-wrap mb-3 mb-md-2 d-flex align-items-center w-100-1630"
                >
                  <span class="k-icon k-i-zoom"></span>
                  <span class="k-textbox k-grid-search k-display-flex">
                    <k-input
                      :class="'mb-0 w-100-vsm max-w-220 max-w-100-vsm'"
                      placeholder="Search"
                      :value="searchWord"
                      @input="onSearch"
                    >
                    </k-input>
                  </span>
                </div>
                <div class="d-flex flex-wrap gap-2 gap-md-3 w-100-1630">
                  <DropDownButton
                    v-if="selectedItems.length >0"
                    :disabled="!hasEditAccess()"
                    :button-class="'border-normal-1 text-color d-flex flex-row-reverse bg-white hvr-bg-primary'"
                    :class="'w-100-vsm'"
                    :icon="'chevron-down'"
                    :theme-color="'primary'"
                    :fill-mode="'outline'"
                    :popup-settings="popupSettings"
                    :items="actionDropDown[selectedUserStatus]"
                    :text="'Action'"
                    @itemclick="onActionClick"
                  />
                  <kbutton
                    v-if="selectedItems.length > 0"
                    :disabled="!hasDeleteAccess()"
                    :theme-color="'primary'"
                    :fill-mode="'outline'"
                    :class="'w-100-vsm border-normal-1 text-color-red-2 bg-white hvr-bg-red-2 hvr-border-red-2 mt-1px'"
                    :icon="'delete'"
                    @click="deleteModal"
                  >
                    Remove
                  </kbutton>
                </div>
                <div v-if="!selectedItems?.length" class="d-flex flex-wrap gap-2 gap-md-3 w-100-1630">
                  <div
                    class="mb-2 mb-md-1 w-100-vsm d-flex flex-wrap flex-vsm-wrap flex-sm-nowrap align-items-center"
                  >
                    <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                      >Filter by List</label
                    >
                    <dropdownlist
                      :style="{ width: '140px' }"
                      :class="'w-100-vsm'"
                      :data-items="dropdownlistDatapass()"
                      :text-field="'text'"
                      :data-item-key="'id'"
                      @change="handleListStatusValue"
                      :value="passListStatusValue"
                      :list-no-data-render="'listTemplate'"
                    >
                      <template v-slot:listTemplate="{props}">
                        <custom :messageShow="'No lists created.'" />
                      </template>
                    </dropdownlist>
                  </div>
                  <div class="mb-2 mb-md-1 w-100-vsm">
                    <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                      >Filter by Tags</label
                    >
                    <dropdownlist
                      :style="{ width: '160px' }"
                      :class="'w-100-vsm'"
                      :data-items="dropdownTagDatapass()"
                      :text-field="'text'"
                      :data-item-key="'id'"
                      @change="handleTagStatusValue"
                      :value="passTagStatusValue"
                      :list-no-data-render="listNoDataRender"
                    >
                      <template v-slot:myTemplate="{props}">
                        <custom :messageShow="'No tags created.'" />
                      </template>
                    </dropdownlist>
                  </div>
                  <div v-if="$route.query.type==2" class="mb-2 mb-md-1 w-100-vsm">
                    <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                      >Filter by Segments</label
                    >
                    <dropdownlist
                      :style="{ width: '160px' }"
                      :class="'w-100-vsm'"
                      :data-items="dropdownSegmentsDatapass()"
                      :text-field="'text'"
                      :data-item-key="'id'"
                      @change="handleSegmentStatusValue"
                      :value="passSegmentStatusValue"
                      :list-no-data-render="listNoDataRender"
                    >
                      <template v-slot:myTemplate="{props}">
                        <custom :messageShow="'No tags created.'" />
                      </template>
                    </dropdownlist>
                  </div>
                  <div class="w-100-vsm mb-2 mb-md-1">
                    <span class="d-flex flex-wrap align-items-center w-100-vsm">
                      <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                        >Filter by Status</label
                      >
                      <dropdownlist
                        :class="'w-100-vsm'"
                        :style="{ width: '140px' }"
                        :data-items="statusList"
                        :text-field="'text'"
                        :data-item-key="'id'"
                        :value="selectedStatus"
                        @change="handleValueChange($event.value, 'selectedStatus')"
                      >
                      </dropdownlist>
                    </span>
                  </div>
                  <div v-if="!selectedItems?.length" class="position-relative">
                    <kbutton
                      @click="columnFilterHandler"
                      ref="buildFromScratchRef"
                      :fill-mode="'outline'"
                      :class="'h-38 w-38 border-normal-1 text-color bg-white hvr-bg-primary'"
                      :theme-color="'primary'"
                      :icon="'table-properties'"
                    >
                    </kbutton>
                    <Popup
                      :anchor="'buildFromScratchRef'"
                      :show="columnFilterPopupVisibility"
                      :popup-class="'all-audience-filter-popup column-filter-popup w-219 radius-8'"
                    >
                      <div
                        v-click-outside="() => this.columnFilterPopupVisibility = false"
                        class="p-16 d-flex flex-wrap flex-column gap-3"
                      >
                        <template
                          v-for="(item,index) in allColumns"
                          :key="index"
                        >
                          <checkbox
                            :class="'font-16 font-w-500 cursor-pointer checkbox-style-2'"
                            :label="item.title"
                            :value="item.isChecked"
                            @change="onColumnSelectionChange($event.value, index)"
                          />
                        </template>
                      </div>
                    </Popup>
                  </div>
                </div>

              </div>
            </div>
          </toolbar>

          <!-- <template v-slot:tags="{props}">
          <td>
            <div class="d-flex">
              <span
                v-if="props?.dataItem?.Tags"
                v-for="(item, index) in getTagFormat(props)"
                :title="item"
                class="tags-box ellipsis max-w-110"
              >
                {{item}}
              </span>

              <span
                class="title-tip title-tip-right tags-box max-w-67 more"
                :title="props?.dataItem?.Tags"
                v-if="props?.dataItem?.Tags?.split(',').length > 2"
              >
                +{{props?.dataItem?.Tags?.split(',').length - 2}} More
              </span>
            </div>
          </td>
        </template> -->

          <template
            v-for="(item,index) in dateCells"
            :key="index"
            v-slot:[item.cell]="{ props }"
          >
            <td>
              <div class="d-flex justify-content-between">
                <span> {{this.$globalDateFormatter(props.dataItem[item.field])}} </span>
              </div>
            </td>
          </template>

          <template v-slot:nameCell="{ props }">
            <td>
              <span @click="routeToMemberDetails(props.dataItem)" class="font-14 font-w-500 color-blue cursor-pointer"
                >{{props.dataItem.name}}</span
              >
            </td>
          </template>

          <template v-slot:subscriberStatusCell="{props}">
            <td>
              <div class="d-flex justify-content-between">
                <span
                  v-if="props.dataItem.subscriberStatus==0"
                  class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8"
                  >Non-subscribed</span
                >
                <span
                  v-if="props.dataItem.subscriberStatus==1"
                  class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 live"
                  >Subscribed</span
                >
                <span
                  v-if="props.dataItem.subscriberStatus==2"
                  class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 draft"
                  >Unsubscribed</span
                >
                <span
                  v-if="props.dataItem.subscriberStatus==3"
                  class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 paused"
                  >Cleaned</span
                >
              </div>
            </td>
          </template>
          <GridNoRecords> No audience found. </GridNoRecords>
        </Grid>
      </template>

      <AddAudience v-if="visibleModal"></AddAudience>
      <ImportDataModal
        v-if="importModalVisible && closeMemberImport"
        @closeMemberImportModal="getCloseMemberImportModal"
        @openMemberMapping="getopenMemberMapping"
        :nullDataInitializer="false"
        @modalHandler="modalHandler"
      >
      </ImportDataModal>
      <importCustomFieldMapping
        :dialogue="false"
        v-if="openMembermapping"
        @closeAllModals="closeAllModals"
        @closeMemberMapping="getopenMemberImport"
        :documentId="importedDocId"
        :nullDataInitializer="false"
        @modalHandler="modalHandler"
        @refetchGridData="this.refetchGridData"
      ></importCustomFieldMapping>

      <AllAudienceDeleteModal
        v-if="deleteModalVisible"
        @selectedLength="selectedLength"
        @allcheckedHandle="allcheckedHandle"
        :listId="this.passListStatusValue.id? this.passListStatusValue.id : null"
        :tagId="this.pramsTagId ? this.pramsTagId : this.passTagStatusValue?.id"
        :selectedItems="selectedItems"
        @deleteHandler="deleteHandler"
      >
      </AllAudienceDeleteModal>
      <div v-if="isOpenConfigureFields">
        <ConfigureFields @cancel="cancel()" @save="save()" />
      </div>
    </div>
  </div>
</div>

<SetStatusActionModal
  :message="actionWarningMessage"
  :onConfirm="onConfirmActionHandler"
  :actionType="actionType"
  v-if="actionmodalVisible"
/>
